<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card-text>
        <p class="text-center text-xl font-weight-semibold black--text mb-2">
          {{ t('login.log_in') }}
        </p>
        <p class="ma-0 pt-4 text-center">
          {{ t('login.enter_password') }}
        </p>
        <p
          class="ma-0 pt-4 text-center"
          style="font-weight: bold; padding-top: 0px !important;"
        >
          {{ logData.type === 'EMAIL' ? logData.code : logData.fullPhone ? formatNumber(logData.phone_number) : '' }}
        </p>
      </v-card-text>

      <v-card-text>
        <v-form
          ref="loginForm"
          @submit.prevent="loginSubmit"
        >
          <!-- <v-text-field
            v-if="!isPhoneVisible"
            v-model="logData.code"
            dense
            outlined
            rounded
            readonly
            :placeholder="t('login.enter_phone_email')"
            hide-details
            class="mb-3 white--text lg-theme"
          /> -->

          <!-- <div
            v-if="isPhoneVisible"
            class="d-flex justify-space-between mb-3"
          >
            <v-select
              v-model="logData.phoneCode"
              class="pr-1 w-code"
              dense
              outlined
              rounded
              readonly
              :items="phoneCodeOptions"
              item-text="text"
              item-value="value"
              :append-icon="null"
              hide-details
            >
              <template v-slot:item="{ item }">
                <img
                  :src="item.image"
                  :alt="item.value"
                  width="20"
                  height="auto"
                  class="mr-2"
                >
                {{ item.text }}
              </template>
            </v-select>

            <v-text-field
              v-model="logData.code"
              class="pl-1"
              :label="t('users.phone')"
              dense
              outlined
              rounded
              readonly
              :disabled="!logData.phoneCode"
              hide-details
            />
          </div> -->

          <v-text-field
            ref="passwordInput"
            v-model="logData.password"
            dense
            outlined
            rounded
            :type="isPasswordVisible ? 'text' : 'password'"
            :placeholder="t('login.password')"
            :error-messages="errorMessages.password"
            :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
            :rules="[validators.required, validators.minLengthValidator(logData.password, 6)]"
            hide-details
            class="white--text lg-theme"
            :style="'caret-color: #333333 !important;'"
            @click:append="isPasswordVisible = !isPasswordVisible"
          >
          </v-text-field>

          <!-- <div class="d-flex align-center justify-center flex-wrap">
            <v-btn
              class="ma-0 py-4 px-1 no-bg-hover"
              style="
                text-transform: inherit;
                min-width: 60px;
              "
              text
              color="secondary"
            >
              {{ t('login.forgot_password') }}
            </v-btn>
          </div> -->

          <v-btn
            block
            color="primary"
            type="submit"
            rounded
            large
            class="mt-6 text-capitalize"
            :disabled="!isFormValid"
          >
            {{ t('login.log_in') }}
          </v-btn>

          <div class="d-flex align-center my-4">
            <v-divider></v-divider>
            <span class="mx-4 font-weight-bold">{{ t('login.or_code') }}</span>
            <v-divider></v-divider>
          </div>

          <v-btn
            block
            color="primary"
            type="submit"
            rounded
            large
            class="mt-6"
            style="text-transform: none;"
            outlined
            @click="onLoginCode('EMAIL')"
          >
            <!-- (typeLogin == LOGIN_TYPE.PHONE_NUMBER ? i18n.t("login.emailCodeTo") + formatEmail(anotherInfo) : formatButtonText(i18n.t("login.emailCodeTo"), phoneOrEmail))  -->
            {{ logData.type !== 'EMAIL' ? t('login.email_code_to') + formatEmail(logData.email) : formatButtonText(t('login.email_code_to'), logData.email) }}
          </v-btn>

          <v-btn
            block
            color="primary"
            type="submit"
            rounded
            large
            class="mt-6"
            style="text-transform: none;"
            outlined
            @click="onLoginCode('PHONE_NUMBER')"
          >
            {{ t('login.text_code_to') +( logData.type ? (logData.type !== 'EMAIL' ? logData.phone_number : formatPhoneNumber(logData.phone_number)) : "") }}
          </v-btn>

          <!-- <div class="d-flex justify-center">
            <v-btn
              class="mt-1 py-2 no-bg-hover"
              color="primary"
              style="text-transform: inherit;"
              text
              @click="onLoginCode"
            >
              {{ t('login.or_code') }}
            </v-btn>
          </div> -->
        </v-form>
        <div
          class="d-flex"
          style="margin-top: 10px;"
        >
          <v-btn
            class="mt-1 py-2 no-bg-hover"
            color="primary"
            style="text-transform: none; font-weight: 700; padding-left: 0px;"
            text
            @click="onForgotPassword"
          >
            {{ t('login.forgot_password') }}
          </v-btn>
        </div>
        <div
          class="d-flex"
          style="margin-top: 0px;"
        >
          <v-btn
            class="mt-1 py-2 no-bg-hover"
            color="primary"
            style="text-transform: none; font-weight: 700; padding-left: 0px;"
            text
            @click="onAnotherAccount"
          >
            {{ t('login.sign_in_another_account') }}
          </v-btn>
        </div>
      </v-card-text>
    </div>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
/* eslint-disable no-confusing-arrow */
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref, computed, onMounted, getCurrentInstance } from '@vue/composition-api'
import { required, emailValidator, minLengthValidator } from '@core/utils/validation'
import { useRouter, formatPhoneNumber, formatEmail, formatButtonText } from '@core/utils'
import themeConfig from '@themeConfig'
import store from '@/store'
import { useUtils } from '@core/libs/i18n'
import usePhone from '@core/utils/usePhone'
import useCryptoJs from '@core/utils/useCryptoJs'
import useLogData from '@core/utils/useLogData'
import { sendCodeSMSUser, sendCodeEmailUser } from '@api'

export default {
  setup() {
    const { router } = useRouter()
    const { t } = useUtils()
    const { phoneCodeOptions, formatNumber } = usePhone()
    const { encrypt } = useCryptoJs()
    const { logData, currentLogData, updateLogData } = useLogData()
    const vm = getCurrentInstance().proxy

    const loginForm = ref(null)
    const passwordInput = ref(null)

    const isPhoneVisible = ref(false)
    const isPasswordVisible = ref(false)
    const errorMessages = ref([])

    const isFormValid = computed(() => {
      if (logData.value.password && minLengthValidator(logData.value.password, 6) === true) return true

      return false
    })

    const loginSubmit = async () => {
      if (loginForm.value.validate()) {
        const body = {
          code: logData.value.type === 'EMAIL' ? logData.value.code : logData.value.fullPhone,
          type: logData.value.type,
          join_game: logData.value.joinGame,
          match_code: logData.value.matchCode,
          reservation_code: logData.value.reservationCode,
          login_route: logData.value.loginRoute,
          password: logData.value.password,
        }
        const encryptBody = encrypt(body)

        const userAbility = [
          {
            action: 'manage',
            subject: 'all',
          },
        ]
        vm.$ability.update(userAbility)

        // remove team
        store.dispatch('auth/loginUserAction', {
          data: encryptBody,
          redirect: logData.value.redirect,
          facility: logData.value.facility,
          field: logData.value.field,
          date: logData.value.date,
          time: logData.value.time,
          slot: logData.value.slot,
          pay: logData.value.pay,
          reservationCode: logData.value.reservationCode,
          showPay: logData.value.showPay,
          team: logData.value.team,
          payType: logData.value.payType,
        })
      }
    }

    const onAnotherAccount = async () => {
      /* logData.value.type = ''
      logData.value.code = ''
      logData.value.fullPhone = ''
      logData.value.phoneCode = ''
      await updateLogData(logData.value) */
      router.push({ name: 'auth-login-phone-email' })
    }

    const onForgotPassword = () => {
      router.push({ name: 'auth-forgot-password' })
    }

    const onLoginCode = async typeSelected => {
      let respSend = null
      let info = null
      let encryptBody = null
      logData.value.encode = logData.value.type
      logData.value.type = typeSelected
      logData.value.type_selected = typeSelected

      if (typeSelected === 'PHONE_NUMBER') {
        info = {
          phone_number: logData.value.phone_number,
        }
        encryptBody = encrypt(info)
        respSend = await sendCodeSMSUser(encryptBody)
      } else {
        info = {
          email: logData.value.email,
        }
        encryptBody = encrypt(info)
        respSend = await sendCodeEmailUser(encryptBody)
      }

      if (respSend.ok) {
        updateLogData(logData.value)
        router.push({ name: 'auth-access-code' })
      }
    }

    onMounted(async () => {
      Object.assign(logData.value, currentLogData.value)
      isPhoneVisible.value = logData.value.type === 'PHONE_NUMBER'
      setTimeout(() => {
        passwordInput.value.focus()
      }, 500)
    })

    return {
      loginForm,
      passwordInput,
      logData,
      isPasswordVisible,
      errorMessages,
      isFormValid,
      onForgotPassword,
      isPhoneVisible,
      phoneCodeOptions,

      // themeConfig
      appName: themeConfig.app.name,
      appLogoTitle: themeConfig.app.logoTitle,

      loginSubmit,
      onLoginCode,
      onAnotherAccount,
      formatNumber,
      formatEmail,
      formatButtonText,
      formatPhoneNumber,
      t,

      validators: {
        required,
        emailValidator,
        minLengthValidator,
      },

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
